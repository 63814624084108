import React from 'react';

const ScheduleSection: React.FC = () => {
  return (
    <section className="p-4 pb-10">
      <h2 className="text-2xl font-bold text-center mb-4">Horários das Palestras</h2>
      <div className="overflow-x-auto">
        <table className="w-full table-fixed border-collapse">
          <thead>
            <tr>
              <th className="w-1/3 border-solid border-2 border-indigo-600">12/09/2025</th>
              <th className="w-1/3 border-solid border-2 border-indigo-600">13/09/2025</th>
              <th className="w-1/3 border-solid border-2 border-indigo-600">14/09/2025</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="w-1/3 border-solid border-2 border-indigo-600"><strong>09:00 - 10:00:</strong> Passe Magnético: Uma Abordagem Científica</td>
              <td className="w-1/3 border-solid border-2 border-indigo-600"><strong>09:00 - 10:00:</strong> História e Evolução do Passe Magnético</td>
              <td className="w-1/3 border-solid border-2 border-indigo-600"><strong>09:00 - 10:00:</strong> Fundamentos do Passe Magnético na Doutrina Espírita</td>
            </tr>
            <tr>
              <td className="w-1/3 border-solid border-2 border-indigo-600"><strong>10:00 - 11:00:</strong> Aplicações Terapêuticas do Passe Magnético</td>
              <td className="w-1/3 border-solid border-2 border-indigo-600"><strong>10:00 - 11:00:</strong> O Passe Magnético na Cura Espiritual</td>
              <td className="w-1/3 border-solid border-2 border-indigo-600"><strong>10:00 - 11:00:</strong> Técnicas Avançadas de Passe Magnético</td>
            </tr>
            <tr>
              <td className="w-1/3 border-solid border-2 border-indigo-600"><strong>11:00 - 12:00:</strong> O Passe Magnético e a Bioenergia Humana</td>
              <td className="w-1/3 border-solid border-2 border-indigo-600"><strong>11:00 - 12:00:</strong> Benefícios Psicológicos do Passe Magnético</td>
              <td className="w-1/3 border-solid border-2 border-indigo-600"><strong>11:00 - 12:00:</strong> O Passe Magnético em Crianças e Adolescentes</td>
            </tr>
            <tr>
              <td className="w-1/3 border-solid border-2 border-indigo-600"><strong>13:00 - 14:00:</strong> Passe Magnético: Mitos e Realidades</td>
              <td className="w-1/3 border-solid border-2 border-indigo-600"><strong>13:00 - 14:00:</strong> A Influência do Passe Magnético na Saúde Mental</td>
              <td className="w-1/3 border-solid border-2 border-indigo-600"><strong>13:00 - 14:00:</strong> O Passe Magnético na Prática Médica</td>
            </tr>
            <tr>
              <td className="w-1/3 border-solid border-2 border-indigo-600"><strong>14:00 - 15:00:</strong> Energia, Vibração e Passe Magnético</td>
              <td className="w-1/3 border-solid border-2 border-indigo-600"><strong>14:00 - 15:00:</strong> Aspectos Éticos do Passe Magnético</td>
              <td className="w-1/3 border-solid border-2 border-indigo-600"><strong>14:00 - 15:00:</strong> O Passe Magnético e as Doenças Psicosomáticas</td>
            </tr>
            <tr>
              <td className="w-1/3 border-solid border-2 border-indigo-600"><strong>15:00 - 16:00:</strong> Passe Magnético e Autoconhecimento: Uma Jornada Espiritual</td>
              <td className="w-1/3 border-solid border-2 border-indigo-600"><strong>15:00 - 16:00:</strong> O Passe Magnético como Complemento à Medicina Tradicional</td>
              <td className="w-1/3 border-solid border-2 border-indigo-600"><strong>15:00 - 16:00:</strong> Passe Magnético: Efeitos no Equilíbrio Energético do Corpo</td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default ScheduleSection;