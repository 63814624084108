import React, { useState, useEffect } from "react";
import axios from "axios";
import { InputMask } from '@react-input/mask';

interface SubscribeModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const SubscribeModal: React.FC<SubscribeModalProps> = ({ isOpen, onClose }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState(""); 
  const [phone, setPhone] = useState("");
  const [cep, setCep] = useState("");
  const [rua, setRua] = useState("");
  const [bairro, setBairro] = useState("");
  const [cidade, setCidade] = useState("");
  const [estado, setEstado] = useState("");
  const [restrictions, setRestrictions] = useState<string[]>([]);
  const [otherRestriction, setOtherRestriction] = useState("");

  const handleCheckboxChange = (e: any) => {
    const { value, checked } = e.target;
    if (checked) {
      setRestrictions([...restrictions, value]);
    } else {
      setRestrictions(
        restrictions.filter((restriction) => restriction !== value)
      );
    }
  };

  const isOtherSelected = restrictions.includes("outro");

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    console.log("Inscrição enviada:", {
      name,
      email,
      phone,
      address: { cep, rua, bairro, cidade, estado },
      restrictions,
      otherRestriction,
    });
    try {
      await axios.post("http://localhost:3000/subscriber", {
        name,
        email,
        phone,
        address: { cep, rua, bairro, cidade, estado },
        restrictions,
        otherRestriction,
      });
    
      // Limpar o formulário após a inscrição
      setName("");
      setEmail("");
      setPhone("");
      setCep("");
      setRua("");
      setBairro("");
      setCidade("");
      setEstado("");
      setRestrictions([]);
      setOtherRestriction("");
      onClose();
    } catch (error) {
      console.error("Erro ao enviar inscrição:", error);
    }
  };

  const fetchAddress = async (cep: string) => {
    try {
      const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
      const data = response.data;
      setRua(data.logradouro);
      setBairro(data.bairro);
      setCidade(data.localidade);
      setEstado(data.uf);
    } catch (error) {
      console.error("Erro ao buscar endereço:", error);
    }
  };

  useEffect(() => {
    if (cep.length === 9) {
      fetchAddress(cep);
    }
  }, [cep]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-lg overflow-y-auto max-h-screen">
        <h2 className="text-xl font-bold mb-4">Formulário de Inscrição</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="name" className="block text-sm font-medium text-gray-700">
              Nome
            </label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full p-2 border border-gray-300 text-sm"
              required
            />
          </div>
          
          <div className="mb-3">
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              Email
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full p-2 border border-gray-300 text-sm"
              required
            />
          </div>
          <div className="mb-3">
            <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
              Telefone
            </label>
            <InputMask 
              mask="+55 (__) _____-____" 
              placeholder="(__) _____-____" 
              replacement={{ _: /\d/ }}
              value={phone}
              onChange={(e:any) => setPhone(e.target.value)}
              className="w-full p-2 border border-gray-300 text-sm"
              required              
            />
          </div>
          <div className="mb-3">
            <label htmlFor="cep" className="block text-sm font-medium text-gray-700">
              CEP
            </label>
            <InputMask 
              mask="_____-___" 
              placeholder="_____-___" 
              replacement={{ _: /\d/ }}              
              value={cep}
              onChange={(e:any) => setCep(e.target.value)}
              className="w-full p-2 border border-gray-300 text-sm"
              required
            />
          </div>
          <div className="mb-3">
            <label htmlFor="rua" className="block text-sm font-medium text-gray-700">
              Rua
            </label>
            <input
              type="text"
              id="rua"
              value={rua}
              onChange={(e) => setRua(e.target.value)}
              className="w-full p-2 border border-gray-300 text-sm"
              required
            />
          </div>
          <div className="mb-3">
            <label htmlFor="bairro" className="block text-sm font-medium text-gray-700">
              Bairro
            </label>
            <input
              type="text"
              id="bairro"
              value={bairro}
              onChange={(e) => setBairro(e.target.value)}
              className="w-full p-2 border border-gray-300 text-sm"
              required
            />
          </div>
          <div className="mb-3">
            <label htmlFor="cidade" className="block text-sm font-medium text-gray-700">
              Cidade
            </label>
            <input
              type="text"
              id="cidade"
              value={cidade}
              onChange={(e) => setCidade(e.target.value)}
              className="w-full p-2 border border-gray-300 text-sm"
              required
            />
          </div>
          <div className="mb-3">
            <label htmlFor="estado" className="block text-sm font-medium text-gray-700">
              Estado
            </label>
            <input
              type="text"
              id="estado"
              value={estado}
              onChange={(e:any) => setEstado(e.target.value)}
              className="w-full p-2 border border-gray-300 text-sm"
              required
            />
          </div>
          <div>
            <div className="mb-3 p-2">
              <label className="block text-lg font-bold py-3">
                Restrições Alimentares
              </label>
              <div className="flex flex-col space-y-2">
                <div className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    value="gluten"
                    checked={restrictions.includes("gluten")}
                    onChange={handleCheckboxChange}
                    className="form-checkbox h-5 w-5 text-blue-600"
                  />
                  <label>Intolerância ao Glúten</label>
                </div>
                <div className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    value="lactose"
                    checked={restrictions.includes("lactose")}
                    onChange={handleCheckboxChange}
                    className="form-checkbox h-5 w-5 text-blue-600"
                  />
                  <label>Intolerância à Lactose</label>
                </div>
                <div className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    value="vegano"
                    checked={restrictions.includes("vegano")}
                    onChange={handleCheckboxChange}
                    className="form-checkbox h-5 w-5 text-blue-600"
                  />
                  <label>Vegano</label>
                </div>
                <div className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    value="vegetariano"
                    checked={restrictions.includes("vegetariano")}
                    onChange={handleCheckboxChange}
                    className="form-checkbox h-5 w-5 text-blue-600"
                  />
                  <label>Vegetariano</label>
                </div>
                <div className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    value="outro"
                    checked={restrictions.includes("outro")}
                    onChange={handleCheckboxChange}
                    className="form-checkbox h-5 w-5 text-blue-600"
                  />
                  <label>Outro</label>
                </div>
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="otherRestriction" className="block text-lg font-medium text-gray-700">
                Especifique outra restrição (se aplicável)
              </label>
              <input
                type="text"
                id="otherRestriction"
                value={otherRestriction}
                onChange={(e:any) => setOtherRestriction(e.target.value)}
                className="w-full p-2 border border-gray-300 text-sm"
                disabled={!isOtherSelected}
              />
            </div>
          </div>
          <div className="flex justify-center space-x-4 mt-6">
            <button
              type="submit"
              className="bg-green-600 text-white px-6 py-3 rounded"
            >
              Concluir
            </button>
            <button
              onClick={onClose}
              className="bg-blue-600 text-white px-6 py-3 rounded"
            >
              Cancelar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SubscribeModal;