import React from 'react';

const ContactSection: React.FC = () => {
  return (
    <section id="contact" className="p-4">
      <h2 className="text-4xl font-bold text-center mb-4 px-4">Contato</h2>
      <p className="text-lg font-bold text-left mb-4 py-6">Tem alguma dúvida sobre o evento? Envie sua pergunta abaixo e responderemos o mais breve possível.</p>
      <form action="mailto:seuemail@exemplo.com" method="post" encType="text/plain">
        <label htmlFor="name" className="block text-lg font-bold">Nome:</label>
        <input type="text" id="name" name="name" required className="w-full p-2 border border-gray-300" />

        <label htmlFor="email" className="block text-lg font-bold">E-mail:</label>
        <input type="email" id="email" name="email" required className="w-full p-2 border border-gray-300" />

        <label htmlFor="message" className="block text-lg font-bold">Mensagem:</label>
        <textarea id="message" name="message" rows={6} required className="w-full p-2 border border-gray-300"></textarea>

        <input type="submit" value="Enviar" className="bg-blue-600 text-white p-4 pl-20 pr-20 rounded cursor-pointer" />
      </form>
    </section>
  );
};

export default ContactSection;