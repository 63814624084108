import React from "react";

const AboutSection: React.FC = () => {
  return (
    <section
      id="#about"
      className="p-4 flex flex-col items-center justify-center"
    >
      <h2 className="text-5xl font-bold text-center mb-6">Sobre o Evento</h2>
      <p className="font-book-antiqua text-gray-700 text-3xl text-center">
        <strong>Bem-vindo ao 1º Encontro de Magnetizadores Espíritas</strong>
      </p>
      <p className="font-book-antiqua text-gray-700 text-3xl text-center">
        <strong>de Minas Gerais!</strong>
      </p>
      <p className="font-monotype-corsiva text-gray-700 text-2xl text-center pt-6">
        Este evento oferece uma oportunidade única para explorar a prática
      </p>

      <p className="font-monotype-corsiva text-gray-700 text-2xl text-center">
        do passe magnético, uma técnica espiritual que utiliza a energia
      </p>
      <p className="font-monotype-corsiva text-gray-700 text-2xl text-center">
        para promover a cura e o bem-estar.
      </p>
      <p className="font-monotype-corsiva text-gray-700 text-2xl text-center">
        Nosso objetivo é compartilhar conhecimentos, práticas e
      </p>
      <p className="font-monotype-corsiva text-gray-700 text-2xl text-center">
        experiências sobre o passe magnético, ajudando a restaurar o{" "}
      </p>
      <p className="font-monotype-corsiva text-gray-700 text-2xl text-center">
        equilíbrio e a saúde através da energia.
      </p>
    </section>
  );
};

export default AboutSection;
