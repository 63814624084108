import React from 'react';

const Footer: React.FC = () => {
  return (
    <footer className="bg-gray-800 text-white p-4 text-center">
      <p>&copy; 2024 EMEMG. Todos os direitos reservados.</p>
      <p>Rod MG 238 KM 22</p> 
      <p>2.5 km após a Iveco entrada a esquerda</p>
    </footer>
  );
};

export default Footer;